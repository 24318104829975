.logoContainer {
    text-decoration: none;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    z-index: 1;
}

.logo {
    width: 100%;
}

@media (max-width: 767px) {
    .logoContainer {
        width: 125px;
    }
}

@media (max-width: 450px) {
    /* .logoContainer {
        width: 100px;
        padding: 10px;
    } */
}