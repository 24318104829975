.wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.7s ease;
    transform: translateX(-100%);
    overflow-y: auto;
    background-color: rgb(85, 85, 85);
}

.wrapper.show {
    transform: translateX(0);
}

@media (max-width: 767px) {
    .wrapper {
        height: auto;
    }
}