.topbarContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 5px;
    box-sizing: border-box;
    display: flex;
    background-color: #00000091;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.7s linear;
    z-index: 300;
}

.bottomContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.hr {
    width: 100%;
    background-color: none;
    border-bottom:  1px white;
    box-sizing: border-box;
    margin: 0;
    color: white;
}

.headingBottom {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomHeadingItem {
    color: white;
    font-size: 11px;
    font-weight: 400;
    padding: 0 20px;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.logo {
    z-index: 1;
    margin: 10px;
}

.numberContainer {
    /* width: 120px; */
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
}

.number {
    font-size: 13px;
    color: inherit;
    text-decoration: none;
    padding: 5px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .headingBottom {
        display: none!important;
    }

    .bottomContainer {
        justify-content: space-between;
    }

    .numberContainer {
        display: flex;
    }
}

@media (max-width: 500px) {
    /* .numberContainer {
        width: 100px;
    } */

    .number {
        font-size: 11px;
    }
}