.wrapper {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialIconContainer {
    text-decoration: none;
    font-size: 2rem;
    margin: 0 20px;
    color: inherit;
    transition: all 0.5s linear;
}

.socialIconContainer:hover {
    /* color: #99a703; */
    color: #ed8129;
}

.socialIcon {
    color: inherit;
}