.reviewContainer {
    width: 100%;
    margin: 50px auto;
    padding: 20px 20px;
    box-sizing: border-box;
    background-image: url('../../Assets/review_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviews {
    position: relative;
    width: 70%;
    margin: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.avatar-container {
    position: relative;
    top: 40%;
    left: 0;
    transform: translate(0, 40%);
    width: 200px;
}

.avatar-img {
    width: 100%;
}

.ratingBody {
    background-color: white;
    padding: 80px 20px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.user {
    font-size: 20px;
    font-weight: 600;
    color:#595c5d;
    margin-bottom: unset;
    margin: 5px auto;
}

.star-container {
    margin: 5px auto;
    margin-bottom: 30px;
}
/*carousel-control*/
.carousel-status {
    display: none;
}

/*carousel component styling*/
.carousel-root {
    width: 100%;
}
.carousel-status {
    display: none;
}

.control-dots {
    top: 0!important;
}

.carousel .control-dots .dot {
    background: #b7c708!important;
}

.control-next::before {
    border-left: 8px solid #626262!important;
}

.control-prev::before {
    border-right: 8px solid #626262!important;
}

@media (min-width: 960px) {
    .control-dots {
        top: 0!important;
    }
}

@media (max-width: 767px) {
    .control-next::before, .control-prev::before {
        display: none!important;
    }
}

@media (max-width: 767px) {
    .reviews {
        width: 100%;
    }
}