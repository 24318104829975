.drawToggleBtn {
    width: 100px;
    display: none;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    color: white;

}

.drawToggle {
    color: inherit;
    font-size: 25px;
    transition: all 0.4s ease;
    /* margin-left: 10px; */
}

.drawToggleContainer:hover .drawToggle {
    transform: scale(1.1);
}

@media (max-width: 767px) {
    .drawToggleBtn {
        display: flex;
    }
}

@media (max-width: 500px) {
    .drawToggleBtn {
        width: 80px;
    }
}