.quoteLinkContainer {
    width: 100%;
    height: 100%;
    /* border: 1px solid #544a72; */
    border: 1px solid #ed8129;
    /* background-color: #544a72; */
    background-color: #ed8129;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quoteLink {
    display: block;
    text-decoration: none;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    /* color: rgb(52, 52, 52); */
    color: white;
}