.footerContainer {
    width: 100%;
    /* padding: 10px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans", sans-serif;
}

.hr {
    width: 100%;
    height: 1px;
    background-color: unset;
    border: none;
    border-bottom: 1px solid rgb(223, 223, 223);
    margin-bottom: 25px;
}

.logoContainer {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 120px;
    margin: 0 20px;
}

.logo:nth-child(2) {
    width: 80px;
    filter: grayscale(0.5);
}

.contactContainer {
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactHeading1 {
    font-size: 1.5rem;
    font-weight: 400;
    color: gray;
    margin: 2px auto;
}

.socialLinkContainer {
    width: 100%;
}

.text {
    margin: 2px auto;
    color: gray;
    font-size: 14px;
}

.headingContainer {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading {
    /* text-decoration: none; */
    color: gray;
    margin: 0 20px;
    font-size: 0.9rem;
    transition: all 0.5s linear;
}

.heading:hover {
    color: #99a703;
}

.copyrightContainer {
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.copyright {
    color: gray;
    font-size: 14px;
    margin: 2px auto;
}

@media (max-width: 500px) {
    .logo {
        width: 100px;
    }

    .heading {
        font-size: 12px;
    }
}