@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.homepageContainer {
    width: 100%;
    font-family: "DM Sans", sans-serif;
}

.section1 {
    width: 100%;
    position: relative;
    z-index: 100;
}

.bgContainer {
    width: 100%;
    height: 100vh;
    min-height: 850px;
}

.bannerVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.8);
}

.elementContainer {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 100%;
    max-width: 1600px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
    overflow: hidden;
}

.headerContainer {
    width: 900px;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerH1 {
    text-align: center;
    color: white;
    font-size: 3.3rem;
    font-weight: 150;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: 40px;
}

.headerP {
    text-align: center;
    color: white;
    font-size: 17px;
    font-optical-sizing: auto;
    font-style: normal;
}

.headerLinkContainer {
    width: 100%;
    margin-top: 40px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerLink {
    position: relative;
    width: 220px;
    height: 40px;
    margin: 0 10px;
    /* border: 1px solid #d0e20d; */
    border: 1px solid #ed8129;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
    /* background-color: #d0e20d; */
    text-decoration: none;
    /* background-color: transparent; */
    background-color: #ed8129;
}

.headerLinkSlider {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #ed8129;
    transition: width 0.8s ease;
    z-index: 0;
}

.headerLinkText {
    /* color: rgb(80, 79, 79); */
    /* color: #ed8129; */
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    transition: color 0.8s ease;
    z-index: 1;
}

/* .headerLink:hover .headerLinkSlider {
    width: 100%;
} */

.headerLink:hover {
    background-color: #d15f01;
}

.contactFormContainer {
    width: 45%;
    padding: 10px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.756);

}

.contactFormHeading {
    font-size: 18px;
    color: #595c5d;
    letter-spacing: 0px;
    font-weight: 500;
    margin-top: 10px;
}

.responsiveContactFormContainer {
    width: 80%;
    display: none;
    z-index: 400;
}

.sidebar {
    position: absolute;
    top: 50%;
    right: 0%;
    z-index: 400;
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    background-color: #d0e20d;
    color: rgb(80, 79, 79);
    border: 1px solid #d0e20d;
    font-size: 13px;
}

.section5 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section5BgContainer {
    position: relative;
    width: 45%;
    padding: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section5Img1 {
    width: 100%;
}

.section5Img2Container {
    position: absolute;
    bottom: 70px;
    right: 70px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
    /* transition: all 0.5s linear; */
    animation: slidingEffect 9.5s infinite ease-in;
}

.section5Img2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section5Img3 {
    width: 100%;
    display: none;
}

@keyframes slidingEffect {
    0%{
        transform: translateX(0);
    }

    25% {
        transform: translateX(50px);
    }

    50% {
        transform: translateX(50px);
    }

    75% {
        /* transform: translateX(0); */
    }

    100% {
        transform: translateX(0);
    }
}

.section5Img2 {
    width: 100%;
    border-radius: 5px;
}

.section5HeadingContainer {
    position: relative;
    width: 45%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
}

.section5Heading {
    font-size: 2.8rem;
    color: grey;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: unset;
    margin: 0;
    margin-bottom: 40px;
}

.section5P1 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: unset;
    margin: 10px 0;
}

.section5P2 {
    line-height: 30px;
    color: #595c5d;
    margin-bottom: unset;
    margin: 10px 0;
}

.section5LinkContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}

.section5Link1 {
    padding: 10px 25px;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: white;
    /* background-color:#544a72; */
    background-color: #ed8129;
    border: 1px solid #ed8129;
    margin-right: 15px;
}

.section5Link2 {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.section5Link2Icon {
    padding: 10px;
    border-radius: 50%;
    background-color: #e1e0e0;
    color:#595c5d;
    margin-right: 10px;
}

.section5Text {
    color: #595c5d;
    font-weight: 600;
}

.section2 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.section2bgContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.section2Bg {
    width: 100%;
}

.section2HeaderContainer {
    position: relative;
    width: 40%;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
}

.section2Heading {
    font-size: 2.8rem;
    color: grey;
    margin: 0;
    margin-bottom: 40px;
    font-weight: 500;
    letter-spacing: 1px;
}

.section2P {
    font-weight: 300;
    font-size: 15px;
    margin: 10px 0;
}

.section2LinkContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.section2Link {
    padding: 10px 30px;
    font-size: 14px;
    margin: 10px 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 2px solid #ed8129;
    font-weight: 600;
    color: #ed8129;
    text-decoration: none;
    transition: all 0.4s linear;
}

.section2Link:hover {
    background-color: #ed8129;
    color: white;
}

.section3 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.section3BgContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section3Bg  {
    width: 100%;
}

.section3HeadingContainer {
    position: relative;
    width: 40%;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #727272;
    border-top: 4px solid #F39F5A;
    border-bottom: 4px solid #F39F5A;
}

.section3Heading {
    font-size: 2.8rem;
    margin: 0;
    margin-bottom: 40px;
    /* color: #d0e20d; */
    color: #F39F5A;
    font-weight: 200;
    letter-spacing: 1px;
}

.section3P {
    font-weight: 300;
    font-size: 15px;
    margin: 10px 0;
    color: white;
}

.section3LinkContainer {
    width: 100%;
    margin: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section3Link {
    padding: 10px 20px;
    box-sizing: border-box;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    margin: 10px auto;
    border: 2px solid #F39F5A;
    font-size: 16px;
    letter-spacing: 1px;
}

.section4 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    overflow: hidden;
}

.review {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reviewHeading {
    color: #595c5d;
    font-size: 2rem;
    font-weight: 200;
    letter-spacing: 1px;
}

.section6 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.section6Header1 {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section6Heading1 {
    color: #909090;
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
    margin-bottom: 40px;
}

.section6P1 {
    font-weight: 300;
    font-size: 15px;
    margin: 10px 0;;
    color: #595c5d;
}

.section6Header2 {
    width: 100%;
    margin: 50px auto;
    /* padding: 40px; */
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.section6BgContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section6Bg {
    width: 100%;
}

.section6HeadingContainer {
    position: relative;
    width: 40%;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #727272;
    border-top: 4px solid #F39F5A;
    border-bottom: 4px solid #F39F5A;
}

.section6Heading2 {
    font-size: 2rem;
    /* color: #d0e20d; */
    color: #F39F5A;
    font-weight: 200;
    margin: 0;
    margin-bottom: 40px;
}

.section6P2 {
    font-weight: 300;
    font-size: 15px;
    margin: 10px 0;
    color: white;
}

.section7 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.section7HeadingContainer {
    position: relative;
    width: 40%;
    padding: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
}

.section7BgContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section7Bg {
    width: 100%;
}

.section7Heading1 {
    margin: 0;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: 100;
    letter-spacing: 1px;
}

.section7Heading2 {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
}

.section7P {
    font-weight: 300;
    font-size: 15px;
    margin: 10px 0;;
    color: #595c5d;
}

.section7Link {
    color: black;
}

.section8 {
    width: 100%;
    max-width: 1500px;
    margin: 50px auto;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media (max-width:1080px) {
    .headerContainer {
        width: 100%;
    }

    .headerLinkContainer {
        justify-content: center;
    }

    .contactFormContainer {
        display: none;
    }

    .responsiveContactFormContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(70%, -50%);
    }

    .section5 {
        padding: 40px;
        flex-direction: column;
    }

    .section5Img1 {
        display: none;
    }

    .section5BgContainer {
        width: 100%;
        padding: 0;
    }

    .section5Img3 {
        display: block;
    }

    .section5Img2Container {
        display: none;
    }

    .section5HeadingContainer {
        width: 95%;
        top: -20px;
        align-items: center;
        border-top: 3px solid #d0e20d;
    }

    .section5LinkContainer {
        justify-content: center;
    }

    .section2 {
        flex-direction: column;
    }

    .section2bgContainer {
        width: 100%;
    }

    .section2HeaderContainer {
        width: 95%;
        top: -40px;
        padding: 2rem;
        box-sizing: border-box;
        border-top: 5px solid #bece09;
        left: 0;
    }

    .section2Heading {
        margin: 20px auto;
    }

    .section2LinkContainer {
        margin: 20px auto;
        align-items: center;
    }

    .section3 {
        flex-direction: column-reverse;
    }

    .section3BgContainer {
        width: 100%;
    }

    .section3HeadingContainer {
        width: 95%;
        top: -30px;
        left: 0;
    }

    .section6Header2 {
        flex-direction: column;
    }

    .section6BgContainer {
        width: 100%;
    }

    .section6HeadingContainer {
        width: 95%;
        top: -30px;
        left: 0;
    }

    .section7 {
        flex-direction: column-reverse;
    }

    .section7BgContainer {
        width: 100%;
    }

    .section7HeadingContainer {
        width: 95%;
        top: -30px;
        left: 0;
        border-top: 5px solid #d0e20d;
    }
}

@media (max-width: 767px) {
    .bgContainer {
        background-attachment: unset;
        min-height: 650px;
    }

    .headerContainer {
        padding: 10px;
    }

    .headerH1 {
        font-size: 2.5rem;
    }

    .section2 {
        margin: 20px auto;
        padding: 0;
    }

    .review {
        padding: 0;
        margin: 20px auto;
    }

    .section3HeadingContainer, .section6HeadingContainer, .section7HeadingContainer {
        padding: 2rem;
    }

    .section2Heading, .section3Heading, .section5Heading, .section6Heading1, .section6Heading2, .section7Heading1 {
        font-size: 2rem;
    }

    .section3 {
        padding: 0;
    }

    .section5 {
        padding: 0;
    }

    .section6 {
        padding: 0;
        margin: 20px auto;
    }

    .section6Header1 {
        padding: 10px;
    }

    .section6Header2 {
        margin: 20px auto;
    }

    .section7 {
        padding: 0;
    }

    .section8 {
        padding: 0;
    }
}

@media (max-width: 550px) {    
    .headerLinkContainer {
        flex-direction: column;
    }

    .headerH1 {
        font-size: 1.5rem;
    }

    .headerP {
        font-size: 14px;
    }

    .headerLink {
        width: 100%;
        margin: 10px auto;
    }

    .section3P {
        font-size: 14px;
    }

    .section5 {
        margin: 30px auto;
    }

    .section3HeadingContainer, .section6HeadingContainer, .section7HeadingContainer {
        padding: 1.5rem;
    }

    .section2Heading, .section3Heading, .section5Heading, .section6Heading1, .section6Heading2, .section7Heading1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .section5LinkContainer {
        flex-direction: column-reverse;
    }

    .section5Link1 {
        width: 100%;
        font-size: 13px;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .section5Link2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        font-size: 13px;
    }

    .section6HeadingContainer {
        padding: 20px;
        align-items: flex-start;
    }

    .section7 {
        margin: 30px auto;
    }

    .section7HeadingContainer {
        padding: 20px;
    }
}