.additionalopbarContainer {
    width: 100%;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
    display: flex!important;
    justify-content: space-between;
    align-items: center;
}

.leftItemContainer {
    padding-left: 10px;
    box-sizing: border-box;
}

.rightItemContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.leftItem {
    font-size: 15px;
    font-weight: 400;
    color: #ed8129!important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.rightItem {
    text-decoration: none;
    text-transform: uppercase;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    margin: 5px;
    padding: 5px;
    box-sizing: border-box;
    /* background-color: #d0e20d; */
    background-color: transparent;
    border: 1px solid #ed8129;
    /* color: rgb(57, 57, 57); */
    color: #ed8129;
    letter-spacing: 1px;
    transition: all 0.5s ease;
}

.rightItem:hover {
    /* background-color: #b4c408; */
    background-color: #ed8129;
    color: white;
}

.hr {
    width: 100%;
    display: block!important;
    color: white;
    border-bottom: 1px white;
    background-color: transparent;
    margin: 1px 0;
}

@media (max-width: 767px) {
    .additionalopbarContainer {
        display: none!important;
    }

    .hr {
        display: none!important;
    }
}