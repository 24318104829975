.popularProductsContainer {
    width: 100%;
    /* margin: 30px auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans", sans-serif;
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    place-content: start;
    gap: 20px;
    padding: 40px;
    box-sizing: border-box;
}

.productContainer {
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.imgContainer {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productImg {
    height: 100%;
    object-fit: cover;
    width: 100%;
    transition: all 0.4s ease;
}

.headingContainer {
    position: relative;
    top: -20px;
    background-color: #ed8129;
    left: 0;
    width: 80%;
    border: 2px solid #ed8129;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.heading {
  font-size: 1.8rem;
  /* color:rgb(125, 125, 125); */
  color: #544a72;
  text-transform: uppercase;
  text-align: center;
  font-weight: 200;
  margin: 0;
  margin-bottom: 40px;
  letter-spacing: 1px;
}

.title {
    display: block;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    /* color: rgb(57, 57, 57); */
    color: white;
    text-transform: uppercase;
    text-align: center;
}

.linkContainer {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productContainer:hover .productImg {
    transform: scale(1.1);
}

.link {
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 2px solid #ed8129;
    text-transform: uppercase;
    color: #ed8129;
    font-size: 14px;
    transition: all 0.4s linear;
    text-align: center;
}

.link:hover {
    /* background-color: #d0e20d; */
    background-color: #ed8129;
    color: white;
}

@media (max-width: 767px) {
    .popularProductsContainer {
        margin: 10px auto;
    }

    .wrapper {
        padding: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .heading {
        font-size: 2rem;
    }
}

@media (max-width: 450px) {
    .wrapper {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .heading {
        font-size: 1.5rem;
    }
}